import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['select', 'options']

  initialize () {
    this.searchPath = this.element.dataset.searchPath
    this.newPath = this.element.dataset.newPath || false
  }

  connect () {
    this.#setup()
    document.addEventListener('turbo:morph', this.#setup.bind(this))
  }

  disconnect () {
    try {
      this.choices.destroy()

      document.removeEventListener('turbo:morph', this.#setup.bind(this))
      if (this.input && this.searchPath) {
        this.input.removeEventListener('input', this.#search)
      }
      this.selectTarget.removeEventListener('addItem', this.#addItem)
      this.selectTarget.removeEventListener('removeItem', this.#removeItem)
    } catch {}
  }

  reload () {
    this.choices.destroy()
    this.#setup()
  }

  #toggleInput () {
    const input = this.element.querySelector('.choices__input.choices__input--cloned')

    if (!input) return

    if (this.selectTarget.length >= parseInt(this.element.dataset.maxItemCount)) {
      input.classList.add('hidden')
    } else {
      input.classList.remove('hidden')
    }
  }

  #setup = () => {
    this.choices = new Choices(this.selectTarget, {
      searchPlaceholderValue: 'Search',
      searchFloor: 1,
      maxItemCount: -1,
      fuseOptions: {
        threshold: 0.2
      },
      searchResultLimit: 10,
      allowHTML: true,
      ...this.#options()
    })
    this.input = this.element.querySelector('input')

    this.selectTarget.addEventListener('addItem', this.#addItem)
    this.selectTarget.addEventListener('removeItem', this.#removeItem)
    if (this.input && this.searchPath) {
      this.input.addEventListener('input', this.#search)
    }

    this.#appendNewLink()
    this.#toggleInput()
  }

  #loadDefaultOptions = () => {
    this.choices.setChoices([], 'value', 'label', true)
    if (this.hasOptionsTarget) {
      [...this.optionsTarget.children].forEach(this.#appendOption)
    }
  }

  #appendOption = (option) => {
    if (
      ![...this.selectTarget.options].some(o => {
        return o.label === option.label
      })
    ) { this.choices.setChoices([option], 'value', 'label', false) }
  }

  #addItem = (event) => {
    const selectedLength = this.selectTarget.options.length
    const maxItemCount = parseInt(this.element.dataset.maxItemCount)

    if (event.detail.customProperties === 'all') {
      this.choices.choiceList.element.querySelectorAll('.choices__item').forEach(item => {
        this.choices.setChoiceByValue(item.dataset.value)
      })
      this.choices.hideDropdown()
      return this.choices.removeActiveItemsByValue('all')
    }

    if (maxItemCount && selectedLength === maxItemCount) {
      this.choices.hideDropdown()
    }

    this.#toggleInput()
  }

  #removeItem = (event) => {
    this.#toggleInput()
  }

  #search = (event) => {
    if (event.target.value) {
      fetch(this.#buildSearchPath(this.searchPath, `q=${event.target.value}`), {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
        .then(response => response.json())
        .then(this.#setSearchOptions)
    } else {
      this.#loadDefaultOptions()
    }
  }

  #buildSearchPath = (path, query) => {
    const [basePath, baseQuery] = path.split('?')
    if (!baseQuery) {
      return `${path}?${query}`
    }

    return `${basePath}?${query}&${baseQuery}`
  }

  #setSearchOptions = (data) => {
    this.choices.setChoices(data, 'value', 'label', true)
  }

  #appendNewLink () {
    if (this.newPath) {
      const dropdownList = this.element.querySelector('.choices__list--dropdown')

      if (dropdownList) {
        dropdownList.insertAdjacentHTML('beforeend', this.#dropdownFooterTemplate())
      }
    }
  }

  #options = () => {
    return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue searchPlaceholderValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
      .split(' ')
      .reduce(this.#optionsReducer, {})
  }

  #optionsReducer = (accumulator, currentValue) => {
    const value = this.element.dataset[currentValue]
    if (value) {
      accumulator[currentValue] = value === 'true' || value === 'false' ? JSON.parse(value) : value
    } else if (currentValue === 'shouldSort') {
      accumulator[currentValue] = false // Set default value for shouldSort
    }
    return accumulator
  }

  #dropdownFooterTemplate () {
    return `
      <div class="select_footer">
        <a href="${this.newPath}" data-turbo-frame="modal" class="select_footer__new" tabindex="-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.8125 9H15.1875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 2.8125V15.1875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Create</span>
        </a>

        <div class="select_footer__help">
          <div class="select_footer__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 11L5 13L3 11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 3V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 5L11 3L13 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 13V3" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            to navigate
          </div>
          <div class="select_footer__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 8L3 11L6 14" stroke="#3F3C43" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 2V11H3" stroke="#3F3C43" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            to select
          </div>
          <div class="select_footer__item"><b>esc</b> to dismiss</div>
        </div>
      </div>
    `
  }
}
